
import { mapGetters } from 'vuex'
import { mapState, storeToRefs } from 'pinia'
import goToCheckout from '@/mixins/goToCheckout'
import { useCheckoutCredit } from '@/stores/checkout_credit'
import { useMarketStore } from '~/stores/market'
import { useGrosseryStore } from '@/stores/grossery'
import { useUiStore } from '@/stores/ui'

export default {
  name: 'BuyOnCreditPopupWarning',
  mixins: [goToCheckout],
  setup() {
    const marketStore = useMarketStore()

    const checkoutCredit = useCheckoutCredit()
    const grosseryStore = useGrosseryStore()
    const { continueCheckoutWithoutGrossery } = storeToRefs(grosseryStore)
    const uiStore = useUiStore()
    return {
      marketStore,
      grosseryStore,
      checkoutCredit,
      continueCheckoutWithoutGrossery,
      uiStore,
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
    }),
    ...mapState(useCheckoutCredit, ['credit_user_balance']),
    getCreditAllowed() {
      return this.marketStore.cart?.credit_allowed
    },
    splitBoxes() {
      const products = this.marketStore.cart?.sellers
        ?.map(orders => orders?.line_items)
        .flat(1)
      return products?.filter(product => {
        return !product?.loanable
      })
    },
    maxCreditLimit() {
      return this.credit_user_balance?.avail_limit_max
    },
  },
  mounted() {
    if (
      this.checkoutCredit.checkFirstCredit &&
      this.getCreditAllowed === 'NOT_AVAILABLE_BY_AMOUNT'
    ) {
      if (typeof dataLayer !== 'undefined') {
        if (!this.maxCreditLimit) {
          dataLayer.push({
            event: 'credit_scr_min_sum_false',
          })
        } else {
          dataLayer.push({
            event: 'credit_scr_deficiently_credit_funds',
          })
        }
      }
    } else if (this.getCreditAllowed === 'AVAILABLE_WITH_REMOVAL') {
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          event: 'credit_scr_some_items_not_available',
        })
      }
    } else if (this.getCreditAllowed === 'NOT_AVAILABLE') {
      dataLayer.push({
        event: 'credit_scr_items_not_available_credit',
      })
    }
  },
  methods: {
    getNameProduct(product) {
      return product?.product_name
    },
    redirectToCheckout() {
      const lang = this.$i18n.locale === 'az' ? '' : `${this.$i18n.locale}/`
      this.$emit('closeModal')
      if (this.$auth.loggedIn) {
        window.location.href = `${window.location.origin}/${lang}checkout?mode=CART_LOAN`
      } else {
        this.$router.push(
          this.localePath({
            name: 'checkout-authentication',
            query: {
              mode: 'CART_LOAN',
            },
          }),
        )
      }
      // this.$router.push(
      //   this.localePath({
      //     name: 'checkout',
      //     query: {
      //       mode: 'CART_LOAN',
      //     },
      //   }),
      // )
    },
    goToRegularCheck() {
      this.$emit('closeModal')
      this.goToCheckout()
    },
    imageUrl(image) {
      return image || '/images/umico-grey-little.svg'
    },
  },
}
