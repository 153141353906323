import { render, staticRenderFns } from "./AppHeader.vue?vue&type=template&id=5905c79a"
import script from "./AppHeader.vue?vue&type=script&lang=js"
export * from "./AppHeader.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_webpack@4.47.0__css-loader@5.2.7_webpack@4.47.0__handle_cutex6v7bvjfvrrsihidbsse5i/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderTopHeader: require('/usr/src/app/components/Header/TopHeader.vue').default,HeaderLogo: require('/usr/src/app/components/Header/Logo.vue').default,HeaderProductCategories: require('/usr/src/app/components/Header/ProductCategories.vue').default,HeaderQuickSearch: require('/usr/src/app/components/Header/QuickSearch/QuickSearch.vue').default,HeaderAppHeaderCompareLink: require('/usr/src/app/components/Header/AppHeader/CompareLink.vue').default,HeaderAppHeaderFavoriteLink: require('/usr/src/app/components/Header/AppHeader/FavoriteLink.vue').default,HeaderAppHeaderCartLink: require('/usr/src/app/components/Header/AppHeader/CartLink.vue').default,HeaderHamburger: require('/usr/src/app/components/Header/Hamburger/Hamburger.vue').default})
