
import { mapGetters } from 'vuex'
import { mapActions, storeToRefs } from 'pinia'
import { useUiStore } from '@/stores/ui'
import { useLoyaltyStore } from '~/stores/loyalty'
import { useSearchHistoryStore } from '~/stores/search_history'
import { useCustomer } from '~/stores/customer'
import { useDcmStore } from '@/stores/dcm'

export default {
  name: 'AppHeader',
  props: {
    boxed: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const uiStore = useUiStore()
    const searchHistoryStore = useSearchHistoryStore()
    const dcmStore = useDcmStore()
    const { resolution, quickSearchExpanded } = storeToRefs(uiStore)
    return {
      searchHistoryStore,
      uiStore,
      dcmStore,
      resolution,
      quickSearchExpanded,
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
      loggedInUser: 'loggedInUser',
    }),

    isSearchVisible() {
      return this.uiStore.isSearchVisible || !this.isSearchButtonVisible
    },
    isSearchButtonVisible() {
      return (
        this.resolution === 'xs' ||
        this.resolution === 'sm' ||
        this.resolution === 'md'
      )
    },
  },
  async mounted() {
    if (!this.loggedInUser && this.isAuthenticated) {
      await this.getUser()
      await this.getCard()
    }
    if (!this.dcmStore.headerB2bForm) {
      await this.dcmStore.getHeaderB2bForm()
    }
  },
  methods: {
    ...mapActions(useCustomer, ['getUser']),
    ...mapActions(useLoyaltyStore, ['getCard']),
  },
}
