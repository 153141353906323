
import { mapWritableState } from 'pinia'
import { useCheckoutCredit } from '@/stores/checkout_credit'

export default {
  name: 'NotAvailableWarning',
  data() {
    return {
      width: 560,
      showCreditWarn: false,
    }
  },
  computed: {
    ...mapWritableState(useCheckoutCredit, ['not_available_cart']),
  },
  methods: {
    closeModal() {
      this.not_available_cart = false
      this.$router.push(this.localePath('cart'))
    },
  },
}
