import {
  RESOLUTIONS,
  ALL_COUNTRIES,
  WHITE_LIST_MARKET_PRODUCTS_PARAMS,
  SEARCH_QUERIES,
} from '@/constants'
import { useCheckoutCredit } from '@/stores/checkout_credit'
import { useEventDataLayer } from '@/stores/event_data_layer/index'
import { usePromoCodeStore } from '@/stores/promocode'
import { useCheckoutStore } from '@/stores/checkout'
import { useCustomer } from '@/stores/customer'
import dayjs from 'dayjs'
import 'dayjs/locale/az'
import 'dayjs/locale/en'
import 'dayjs/locale/ru'
import i18n from '@/plugins/i18n'

let deepth

function getMenuDeepth(self) {
  deepth = 0
  if (self && self.parent) {
    getMenuDeepth(self.parent)
    deepth++
  } else if (self && self.data && self.parent === null) {
    deepth++
  }
  return deepth
}

function search(el, keyword) {
  const type = Array.isArray(el) ? 'array' : typeof el
  const searchFunc = getFuncByType(type)

  return searchFunc(el, keyword)
}

function getFuncByType(type) {
  const match = {
    string: searchInText,
    number: searchInText,
    boolean: searchInText,
    array: searchInArray,
    object: searchInObject,
  }

  if (typeof match[type] !== 'undefined') {
    return match[type]
  } else {
    throw new TypeError(`Unknown element type "${type}"`)
  }
}

function searchInText(text, keyword) {
  return text.toString()?.toLowerCase().indexOf(keyword) !== -1
}

function searchInObject(obj, keyword) {
  return searchInArray(obj && Object.values(obj).map(item => item), keyword)
}

function searchInArray(arr, keyword) {
  return arr && arr.find(el => search(el, keyword)) !== undefined
}

export const findFromList = (list, keyword) => {
  return list.filter(el => search(el, keyword?.toLowerCase()))
}

export const serialize = (obj, prefix) => {
  const str = []
  let p
  for (p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      const k = prefix ? prefix + '[' + p + ']' : p
      const v = obj[p]
      let objString = ''
      if (v !== null && typeof v === 'object') {
        v.map(i => {
          objString += `${k}=${i}&`
        })
      }
      objString = objString.substring(0, objString.length - 1)
      str.push(
        v !== null && typeof v === 'object'
          ? objString
          : encodeURIComponent(k) + '=' + encodeURIComponent(v),
      )
    }
  }
  return str.join('&')
}

function getTimestamp(date) {
  return new Date(date).getTime()
}

export const calcDistance = (lat1, lon1, lat2, lon2) => {
  function toRad(Value) {
    return (Value * Math.PI) / 180
  }

  const R = 6371 // km
  const dLat = toRad(lat2 - lat1)
  const dLon = toRad(lon2 - lon1)
  const _lat1 = toRad(lat1)
  const _lat2 = toRad(lat2)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(_lat1) * Math.cos(_lat2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return R * c
}

export const dateToInt = date => {
  let hours, minutes
  if (typeof date === 'object') {
    hours = date.getHours()
    minutes = date.getMinutes()
    minutes = minutes < 10 ? '0' + minutes : minutes
  } else {
    date.split(':')
    hours = date.split(':')[0]
    minutes = date.split(':')[1]
    hours = hours || 12
  }
  return +(hours + '' + minutes)
}

export const getResolutionName = W => {
  if (W <= RESOLUTIONS.sm) return 'sm'
  if (W <= RESOLUTIONS.md) return 'md'
  if (W <= RESOLUTIONS.lg) return 'lg'
  if (W <= RESOLUTIONS.xl) return 'xl'
  if (W <= RESOLUTIONS.xxl) return 'xxl'
}

export const deleteZeroKeys = obj => {
  const result = {}
  for (const key in obj) {
    if (obj[key]) result[key] = obj[key]
  }
  return result
}

export const deepFreeze = object => {
  // Retrieve the property names defined on object
  const propNames = Object.getOwnPropertyNames(object)

  // Freeze properties before freezing self

  for (const name of propNames) {
    const value = object[name]

    object[name] =
      value && typeof value === 'object' ? deepFreeze(value) : value
  }

  return Object.freeze(object)
}

export const vowelSuffix = (str, in_suffix) => {
  const vowel_list = 'aeiouəüöıAEIOUƏÜÖI'
  const soft_vovels = ['ə', 'e', 'ö', 'ü', 'i']
  const varray = []
  const vovel_suffix = 'də'
  const vovel_suffix_in = 'dəki'
  const consonant_suffix = 'da'
  const consonant_suffix_in = 'dakı'
  for (const x in str) {
    if (vowel_list.includes(str[x])) {
      varray.push(str[x])
    }
  }
  if (varray.length) {
    if (soft_vovels.includes(varray[varray.length - 1]?.toLowerCase())) {
      str = str + (in_suffix ? vovel_suffix_in : vovel_suffix)
    } else {
      str = str + (in_suffix ? consonant_suffix_in : consonant_suffix)
    }
  }
  return str
}

export function createCategoriesObject(
  categories = [],
  result = {},
  parent = undefined,
) {
  for (const cat of categories) {
    result[cat.id] = {
      id: cat.id,
      name: cat.attributes.name,
      parent: parent.id || '',
    }
    if (!parent.children.includes(cat.id)) parent.children.push(cat.id)

    if (cat.children && cat.children.data && cat.children.data.length) {
      if (!result[cat.id].children) result[cat.id].children = []
      createCategoriesObject(
        cat.attributes.children.data,
        result,
        result[cat.id],
      )
    }
  }
}

export function getHeaders(value) {
  return {
    'Content-Language': value || 'az',
    'Accept-Language': value || 'az',
    HTTP_ACCEPT_LANGUAGE: value || 'az',
    HTTP_CONTENT_LANGUAGE: value || 'az',
  }
}

Math.easeInOutQuad = function (t, b, c, d) {
  t /= d / 2
  if (t < 1) return (c / 2) * t * t + b
  t--
  return (-c / 2) * (t * (t - 2) - 1) + b
}

export function scrollToOld(element, to, duration) {
  let elScrollTop = (element && element.scrollTop) || 0
  const start = elScrollTop
  const change = to - start
  let currentTime = 0
  const increment = 20

  const animateScroll = function () {
    currentTime += increment
    elScrollTop = Math.easeInOutQuad(currentTime, start, change, duration)
    window.scrollTo({ top: elScrollTop })
    if (currentTime < duration) {
      setTimeout(animateScroll, increment)
    }
  }
  animateScroll()
}

export function scrollTo(element, to, duration) {
  if (duration <= 0) return
  const difference = to - element.scrollTop
  const perTick = (difference / duration) * 10
  setTimeout(() => {
    element.scrollTop = element.scrollTop + perTick
    if (element.scrollTop === to) return
    scrollTo(element, to, duration - 10)
  }, 10)
}

export function scrollWithoutDuration(element, to) {
  element.scroll(0, to)
}

export function normalizeMPCategories(categories, lang) {
  const mp_categories = []
  categories.map(category => {
    mp_categories.push({
      child_categories: category.child_ids,
      is_adult: category.is_adult,
      category_id: category.id,
      category_name: category.name,
      translit_name: category.slugged_name,
      parent_id: category.parent_id,
      thumbnail_url: category.icons.thumbnail,
      view_mode: category.view_mode,
      node_type: category.node_type,
      ref_category_id: category.ref_category_id,
      ref_translit_name: category[`node_category_slugged_name_${lang}`],
    })
  })
  return mp_categories
}

export function normalizeMPShortcutCategories(categories) {
  const mp_categories = []
  categories.map(category => {
    if (
      category?.category &&
      category?.category?.active &&
      category?.category?.is_visible
    ) {
      mp_categories.push({
        child_categories: category?.category?.child_ids,
        is_adult: category?.category?.is_adult,
        category_id: category?.category?.id,
        category_name: category?.name,
        translit_name: category?.category?.slugged_name,
        parent_id: category?.category?.parent_id,
        // original_img_url: category?.icons.original,
        svg_icon_url: category?.icons_svg.original,
        thumbnail_url: category?.icons.thumbnail,
        view_mode: category?.category?.view_mode,
        node_type: category.node_type,
        ref_category_id: category.ref_category_id,
      })
    }
  })
  return mp_categories
}

export function normalizeMPMenuCategories(categories, lang) {
  const mp_categories = []
  categories?.map(category => {
    mp_categories.push({
      is_adult: category?.is_adult,
      child_categories: category.child_ids,
      category_id: category.id,
      category_name: category.name,
      translit_name: category.slugged_name,
      parent_id: category.parent_id,
      thumbnail_url: category.icons.thumbnail,
      view_mode: category.view_mode,
      node_type: category.node_type,
      ref_category_id: category.ref_category_id,
      ref_translit_name: category[`node_category_slugged_name_${lang}`],
    })
  })
  return mp_categories
}

let breadcrumb = []

export function createMPBreadCrumbs(self, start_node_id) {
  breadcrumb = []
  if (self && self.parent) {
    createMPBreadCrumbs(self.parent)
    const b = {
      id: self.data.id,
      name: self.data.name,
      ascii_icon: self.data.ascii_icon,
      slug: self.data.slugged_name,
      parent: self?.parent?.data?.id || null,
    }
    if (+start_node_id === +self.data.id) {
      b.current = true
    }
    breadcrumb.push(b)
  } else if (self && self.data && self.parent === null) {
    const _b = {
      id: self.data.id,
      name: self.data.name,
      ascii_icon: self.data.ascii_icon,
      slug: self.data.slugged_name,
    }
    if (+start_node_id === +self.data.id) {
      _b.current = true
    }
    breadcrumb.push(_b)
  }
  return breadcrumb
}

let breadcrumb_category = []

export function createMPBreadCrumbsCategory(self, lang) {
  breadcrumb_category = []
  if (self && self.parent_tree) {
    createMPBreadCrumbsCategory(self.parent_tree, lang)
    const b = {
      id: self?.id,
      name: self[`name_${lang}`],
      ascii_icon: self?.ascii_icon,
      parent_tree: self?.parent_tree?.id || null,
      slug: self[`slugged_name_${lang}`],
    }
    breadcrumb_category.push(b)
  } else if (self && self.parent_tree === null) {
    const _b = {
      id: self.id,
      name: self[`name_${lang}`],
      ascii_icon: self.ascii_icon,
      slug: self[`slugged_name_${lang}`],
    }
    breadcrumb_category.push(_b)
  }
  return breadcrumb_category
}

export function createPromotionMPBreadCrumbs(data) {
  breadcrumb = []
  if (data) {
    const b = {
      id: data.title.id,
      name: data.title.text,
      slug: data.title?.slugged_name,
      parent: data?.title?.parent_id || null,
    }
    breadcrumb.push(b)
  }
  return breadcrumb
}

let breadcrumbForMenu = []
let isLevel2Root = false

export function createMPCategoriesMenuBreadCrumbs(self, start_node_id, isRoot) {
  let deepth = getMenuDeepth(self)
  if (isRoot) {
    isLevel2Root = isRoot && deepth === 2
  }

  if (!start_node_id) breadcrumbForMenu = []

  const menuItem = {
    id: self?.data?.id,
    name: self?.data?.name,
    slug: self?.data?.slugged_name,
    parent: self?.parent?.data?.id || null,
  }

  if (isLevel2Root) {
    deepth = deepth - 1
  }
  if (breadcrumbForMenu?.length > deepth - 1) {
    breadcrumbForMenu = [...breadcrumbForMenu.slice(0, deepth - 1)]
  }

  breadcrumbForMenu.splice(deepth, 0, menuItem)
  // WARNING same category
  if (breadcrumbForMenu?.length >= 3) {
    if (
      breadcrumbForMenu[breadcrumbForMenu?.length - 1]?.parent ===
      breadcrumbForMenu[breadcrumbForMenu?.length - 2]?.parent
    ) {
      breadcrumbForMenu = [...breadcrumbForMenu.slice(0, deepth - 2)]
      breadcrumbForMenu.splice(deepth, 0, menuItem)
    }
  }
  // WARNING

  return breadcrumbForMenu
}

export function createMPCategoriesMenuBreadCrumbsExistCat(
  self,
  start_node_id,
  isRoot,
) {
  let deepth = getMenuDeepth(self)

  isLevel2Root = true
  if (!start_node_id) breadcrumbForMenu = []

  const menuItem = {
    id: self?.category_id,
    name: self?.category_name,
    slug: self?.translit_name,
    parent: self?.parent_id || null,
  }
  if (isRoot) {
    isLevel2Root = isRoot && deepth === 2
  }
  if (isLevel2Root) {
    deepth = deepth - 1
  }

  if (breadcrumbForMenu?.length > deepth) {
    breadcrumbForMenu = [...breadcrumbForMenu.slice(0, deepth)]
  }

  breadcrumbForMenu.splice(deepth, 0, menuItem)
  return breadcrumbForMenu
}

export function normalizeMPMerchants(merchants) {
  const _merchants = {}
  merchants.map(merchant => {
    _merchants[merchant.attributes.ext_id] = {
      name: merchant.attributes.marketing_name,
      id: merchant.attributes.marketing_name_id,
      uuid: merchant.attributes.ext_id,
      logo: merchant.attributes.logo,
    }
  })
  return _merchants
}

export function normalizeMPProducts(products, merchants) {
  return products.map(product => {
    product.merchant = merchants[product && product.merchant_uuid] || null
    if (product.merchant_uuid) delete product.merchant_uuid
    return product
  })
}

export function normalizeMPFilters(filters, page) {
  const _filters = {}

  let defaultFilTers = filters.filter(filter => filter.field_type === 'base')
  const customFilters = filters.filter(filter => filter.field_type === 'custom')

  defaultFilTers.map(defaultFilter => {
    if (defaultFilter.field === 'merchant_uuid') {
      defaultFilter.buckets.map(bucket => {
        return {
          uuid: bucket.title,
          title: bucket.marketing_name,
          marketing_name: bucket.marketing_name,
        }
      })
    } else if (
      defaultFilter.field === 'has_discount' ||
      defaultFilter.field === 'has_taxit_available' ||
      defaultFilter.field === 'has_installment' ||
      defaultFilter.field === 'premium_partner'
    ) {
      defaultFilter.checked = false
      if (!defaultFilter.buckets) {
        defaultFilter.buckets = []
      }
    }
  })

  defaultFilTers.map(filter => {
    if (Object.prototype.hasOwnProperty.call(filter, 'buckets')) {
      filter.buckets.map(bucket => {
        bucket.checked = false
      })
    }
    if (
      Object.prototype.hasOwnProperty.call(filter, 'field') &&
      filter.field === 'retail_price'
    ) {
      filter.selectedMin = filter.min
      filter.selectedMax = filter.max
    }
  })

  customFilters.map(filter => {
    if (Object.prototype.hasOwnProperty.call(filter, 'buckets')) {
      filter.buckets.map(bucket => {
        bucket.checked = false
      })
    }
  })

  if (page === 'mega-discounts') {
    defaultFilTers = defaultFilTers.filter(
      filter => filter.field !== 'has_discount',
    )
  }
  _filters.default = defaultFilTers
  _filters.custom = customFilters
  return _filters
}

export function objectFlip(obj) {
  const ret = {}
  Object.keys(obj).forEach(key => {
    ret[obj[key]] = key
  })
  return ret
}

export function scrollToTargetAdjusted(el, offset = 120, valid, inValid) {
  const elements = document.querySelectorAll(el)
  const filteredElements = []
  Array.prototype.forEach.call(elements, element => {
    if (element.clientWidth) {
      filteredElements.push(element)
    }
  })
  if (filteredElements.length) {
    const bodyRect = document.body?.getBoundingClientRect().top
    const elementRect = filteredElements[0]?.getBoundingClientRect().top
    const elementPosition = elementRect - bodyRect
    const offsetPosition = elementPosition - offset
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    })
    inValid(filteredElements)
  } else {
    valid()
  }
}

export function getLastTypePromoCod(list_promocodes, type) {
  let result = list_promocodes.filter(item => item.type === type)
  result = result
    .sort((a, b) => {
      return getTimestamp(a?.end_date) - getTimestamp(b?.end_date)
    })
    .shift()
  return result
}

export function getLastTypesPromoCod(list_promocodes, types) {
  const result = list_promocodes.filter(item => types.includes(item.type))
  return result || []
}

export function isNotNull(v) {
  return v && v.trim().length && !v.trim().toLowerCase().includes('null')
}

export function isNull(v) {
  return !(v && v.trim().length && !v.trim().toLowerCase().includes('null'))
}

// opening_hours.js
const daysInWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']

export function getStatus(workingHours, datetime, locale) {
  if (!workingHours || !Array.isArray(workingHours) || !workingHours.length) {
    return {
      error: 'Wrong working_hours array',
    }
  }

  if (checkWholeWeek(workingHours))
    return {
      open: true,
      whole_week: true,
    }

  let now
  if (datetime) now = new Date(datetime)
  else now = new Date()

  const dayName = daysInWeek[now.getDay()]
  const workingDay = workingHours.find(d => d.day_of_week === dayName)
  if (!workingDay) {
    return {
      error: 'Failed to find day by day name',
    }
  }
  const open = getState(now, workingDay)
  return {
    open,
    next: getNext(open, workingHours, workingDay, locale),
  }
}

function checkWholeWeek(workingHours) {
  return workingHours.every(date => date.whole_day)
}

function getNowInt() {
  const now = new Date()
  const minuteNow = now.getMinutes()
  const hoursNow = now.getHours()
  return hoursNow * 100 + minuteNow
}

function getState(now, workingDay) {
  if (workingDay.non_working_day) return false
  const nowInt = getNowInt()
  const toInt = workingDay.to_int ? workingDay.to_int : 2400
  if (workingDay.from_int > toInt) {
    return nowInt > workingDay.from_int || nowInt < workingDay.to_int
  }
  if (workingDay.from_int && workingDay.from_int > nowInt) {
    return false
  }
  if (workingDay.to_int && workingDay.to_int < nowInt) {
    return false
  }
  return true
}

function getNextDate(today, next, locale) {
  const todayIndex = daysInWeek.indexOf(today.day_of_week)
  const nextWeek = [
    ...daysInWeek.slice(todayIndex + 1),
    ...daysInWeek.slice(0, todayIndex),
  ]
  const nextDaysCount = nextWeek.indexOf(next.day_of_week)
  if (nextDaysCount === 0) {
    return 'tomorrow'
  } else {
    return dayjs()
      .locale(locale)
      .add(nextDaysCount + 1, 'days')
      .format('D MMMM')
  }
}

function getNext(open, workingHours, workingDay, locale) {
  const next = {
    time: null,
    date: null,
  }

  const nowInt = getNowInt()
  if (open && workingDay.to) {
    next.time = workingDay.to
    return next
  }
  if (!open && !workingDay.non_working_day && workingDay.from_int > nowInt) {
    next.time = workingDay.from
    return next
  }
  const sortedWorkingHours = workingHours.sort((a, b) => {
    return daysInWeek.indexOf(a.day_of_week) - daysInWeek.indexOf(b.day_of_week)
  })
  const index = sortedWorkingHours.findIndex(
    d => d.day_of_week === workingDay.day_of_week,
  )
  for (const d of [
    ...sortedWorkingHours.slice(index + 1),
    ...sortedWorkingHours.slice(0, index),
  ]) {
    if (open && d.to) {
      next.time = d.to
      return next
    }
    if (!open && !d.non_working_day && d.from) {
      next.time = d.from
      next.date = getNextDate(workingDay, d, locale)
      return next
    }
  }
}

// setOptionsOrderPackage
export function setOptionsOrderPackage(ordersPackages) {
  const orders = []
  ordersPackages?.orders?.forEach(order => {
    const current_order = {
      id: order?.id,
      shipping: {
        date: order?.shipping?.date,
        time_frame: order?.shipping?.time_frame,
      },
      install_service_needed: order?.install_service_needed,
      max_installment_months: order?.max_installment_months || 0,
    }
    if (ordersPackages.payment_method === 'CARD_ECOM_KB_INSTALLMENT') {
      current_order.installment_months = order.installment_months
    }
    orders.push(current_order)
  })
  const options = {
    state: ordersPackages?.state,
    payment_method:
      ordersPackages.payment_method === 'CARD_ECOM_KB_INSTALLMENT'
        ? 'CARD_ECOM_KB'
        : ordersPackages?.payment_method,
    shipping: {
      method: ordersPackages?.shipping?.method,
      address: {
        city: ordersPackages?.shipping?.address?.city,
        street: ordersPackages?.shipping?.address?.street,
        building: ordersPackages?.shipping?.address?.building,
        precision: ordersPackages?.shipping?.address?.precision,
        apartment: ordersPackages?.shipping?.address?.apartment,
        kind: ordersPackages?.shipping?.address?.kind,
        note: ordersPackages?.shipping?.recipient?.note,
      },
      recipient: {
        name: ordersPackages?.shipping?.recipient?.name,
        surname: ordersPackages?.shipping?.recipient?.surname,
        phone_number: ordersPackages?.shipping?.recipient?.phone_number,
        email: ordersPackages?.shipping?.recipient?.email,
      },
      latitude: Number(ordersPackages?.shipping?.latitude),
      longitude: Number(ordersPackages?.shipping?.longitude),
    },
    orders,
    promo_code: ordersPackages?.promocode,
    premium_promo_code: ordersPackages?.premium_promocode,
    note: ordersPackages?.shipping?.recipient?.note,
    employee_code: ordersPackages?.employee_code,
    clarification_call_needed: ordersPackages?.clarification_call_needed,
  }
  if (ordersPackages?.shipping?.recipient?.fin_code) {
    options.shipping.recipient.fin_code =
      ordersPackages?.shipping?.recipient?.fin_code
  }
  if (
    ordersPackages.shipping.method === 'PUDO' ||
    ordersPackages.shipping.method === 'PUDO_AZERPOCT'
  ) {
    delete options.shipping.address
  }
  if (
    ordersPackages?.payment_method === 'LOAN_ON_DELIVERY' ||
    ordersPackages?.payment_method === 'LOAN_ON_DELIVERY_DP'
  ) {
    delete options.shipping.recipient.email
  }
  if (ordersPackages?.shipping?.pudo_point_id)
    options.shipping.pudo_point_id = ordersPackages?.shipping?.pudo_point_id
  return options
}

// cityUtils.js
export const getCityInfo = ({ cityInfo, app } = {}) => {
  const defaultCoordinate = '40.372508,49.842474'
  const defaultCityName =
    app?.i18n?.messages?.[app?.i18n?.locale]?.baku || 'Baku'
  const defaultCountryId = 1
  const defaultCityId = 1

  // If cityInfo is not provided, return default city information
  if (!cityInfo) {
    return {
      cityName: defaultCityName,
      countryId: defaultCountryId,
      cityId: defaultCityId,
      selectedCoordinate: defaultCoordinate,
    }
  }

  // If cityInfo is provided, return the relevant city information
  return {
    cityName: cityInfo?.attributes?.name,
    countryId: defaultCountryId,
    cityId: cityInfo?.id,
    selectedCoordinate: `${cityInfo?.attributes?.lat},${cityInfo?.attributes?.lng}`,
  }
}

// dataLayerSearch

function setPageType(route) {
  let page_type = ''
  switch (route?.name) {
    case 'merchant-merchant___ru':
      page_type = 'merchant'
      break
    case 'merchant-merchant___az':
      page_type = 'merchant'
      break
    case 'search-term___az':
      page_type = 'search'
      break
    case 'search-term___ru':
      page_type = 'search'
      break
    case 'tags-tag___az':
      page_type = 'tag'
      break
    case 'tags-tag___ru':
      page_type = 'tag'
      break
    case 'brands-brand___ru':
      page_type = 'brand'
      break
    case 'brands-brand___az':
      page_type = 'brand'
      break

    case 'product-slug___ru':
      page_type = 'product'
      break
    case 'product-slug___az':
      page_type = 'product'
      break
    case 'categories-slug___ru':
      page_type = 'categories'
      break
    case 'categories-slug___az':
      page_type = 'categories'
      break
    default:
      ''
      break
  }
  return page_type
}

function setSuggest(route) {
  let suggestType = ''
  const sourceMap = {
    categories: 'category',
    tags: 'tag',
    search: 'tag',
    product: 'product',
    merchant: 'merchant',
    brand: 'brand',
  }
  Object.entries(sourceMap).forEach(([key, value]) => {
    if (route?.name.includes(key)) suggestType = value
  })
  return suggestType
}

export const setViewSearchResultsSetup = function (
  route = '',
  pagination = 1,
  locale,
) {
  const setEventDataLayer = useEventDataLayer()
  if (
    typeof dataLayer !== 'undefined' &&
    route?.query?.from_search &&
    route.query?.event === 'view_search_results'
  ) {
    dataLayer.push({
      event: 'view_search_results',
      page_type: setPageType(route),
      query: route?.name?.includes('tags')
        ? route?.query?.tag_name
        : route?.query?.query,
      source: route?.name?.includes('category-slug') ? 'companies' : 'products',
      quantity: pagination,
      lang: locale,
      search_type: route?.query?.search_type,
    })
    setEventDataLayer.SET_DATA_CLICK_SUGGESTS(false)
  }
  if (
    typeof dataLayer !== 'undefined' &&
    route.query?.event === 'view_search_tips'
  ) {
    dataLayer.push({
      event: 'view_search_tips',
      query: route?.query?.query,
      source: route?.name?.includes('category-slug') ? 'companies' : 'products',
      lang: locale,
      suggest: setSuggest(route),
      page_type: setPageType(route),
      choice: route.query?.choice,
      quantity: pagination,
      tips_type: route.query?.tips_type,
      ...(route?.name.includes('product-slug')
        ? { position_number: +route.query?.position_number }
        : {}),
    })
  }
}

// dataLayer.js

function setPaymentMethod(method) {
  // 'CASH_ON_DELIVERY' - cash -"Наличными"
  // 'CARD_ON_DELIVERY - card_courier  "Банковской картой при получении"
  // 'CARD_ECOM_KB' - card_online "Банковской картой онлайн"
  // 'LOAN_ON_DELIVERY' -loan - рассрочка онлайн

  let payment_method
  switch (method) {
    case 'CASH_ON_DELIVERY':
      payment_method = 'cash'
      break
    case 'CARD_ON_DELIVERY':
      payment_method = 'card_courier'
      break
    case 'CARD_ECOM_KB':
      payment_method = 'card_online'
      break
    case 'LOAN_ON_DELIVERY':
      payment_method = 'loan'
      break
    default:
      payment_method = 'cash'
  }
  return payment_method
}

function setDeliveryMethod() {
  const CheckoutStore = useCheckoutStore()
  const method = CheckoutStore.orderPackage?.shipping?.method
  let delivery_method
  switch (method) {
    case 'PUDO':
      delivery_method = 'self_pickup'
      break
    case 'COURIER_STANDARD':
      delivery_method = 'courier_delivery'
      break
    case 'COURIER_EXPRESS':
      delivery_method = 'express_delivery'
      break
    case 'PUDO_AZERPOCT':
      delivery_method = 'azerpost'
      break
    default:
      delivery_method = ''
  }
  return delivery_method
}

function setDataLayerPurchase(order, totalAmountAzn, items, method) {
  const checkoutStore = useCheckoutStore()
  const cart_id = checkoutStore.userCartId || checkoutStore.cartId
  const PromoCodeStore = usePromoCodeStore()
  const EventDataLayer = useEventDataLayer()
  const purchase = {
    event: 'purchase',
    method,
    extra_cashback: PromoCodeStore.personal_promo_code_data_layer
      ? 'active'
      : 'inactive',
    ecommerce: {
      purchase: {
        affiliation: order.seller.id,
        transaction_id: order.number,
        value: totalAmountAzn,
        shipping: order.shipping_cost,
        currency: 'AZN',
        tax: 0,
        items,
        payment_type: order?.payment_method?.code,
        shipping_tier: order?.shipping?.method?.code,
      },
    },
    cart_id,
    payment_method: setPaymentMethod(order.payment_method.code),
    delivery_method: setDeliveryMethod(),
  }

  if (PromoCodeStore.personal_promo_code_subscription_data_layer?.code) {
    purchase.switch_extra_cashback =
      PromoCodeStore.has_subscription_promo_code_data_layer ? 'on' : 'off'
  }

  if (typeof dataLayer !== 'undefined') {
    dataLayer.push(purchase)
    dataLayer.push(function () {
      this.reset()
    })
  }
  const promoCodeStore = usePromoCodeStore()
  promoCodeStore.SET_DATA_LAYER_HAS_SUBSCRIPTIOON_PROMO_CODE(false)
  promoCodeStore.SET_DATA_LAYER_HAS_PERSONAL_PROMO_CODE_SUBSCRIPTION({})
  promoCodeStore.SET_DATA_LAYER_PERSONAL_PROMO_CODE(false)
}

function typeCredit() {
  const CheckoutCredit = useCheckoutCredit()
  return CheckoutCredit.checkFirstCredit ? 'credit_new' : 'credit_old'
}

export const setDataLayerPurchaseLocal = function (orders = []) {
  if (orders?.length) {
    orders.map(order => {
      const items = []
      const promocode = order?.promocode_discount ? order?.promocode : false
      order.line_items.map((item, index) => {
        const fixedPrice = Math.round(item?.price * 100) / 100
        const set_item = {
          item_id: item?.product_id,
          item_name: item?.product_name,
          item_category: `${item?.category?.name}_${item?.category?.id}`,
          item_list_name: item?.src_item_list_name,
          item_list_id: item?.item_add_src,
          item_brand: item.brand,
          price: fixedPrice,
          quantity: item.quantity,
          index:
            item?.src_item_list_index !== null
              ? item?.src_item_list_index + 1
              : null,
        }
        if (promocode) set_item.coupon = promocode
        items.push(set_item)
      })
      const totalAmountAzn = Math.round(order.total_amount * 100) / 100
      const method =
        order.payment_method.code === 'LOAN_ON_DELIVERY'
          ? typeCredit()
          : 'regular'
      setDataLayerPurchase(order, totalAmountAzn, items, method)
    })
  }
}

// normalize category
function getPath(item, path = []) {
  if (item.data.attributes.parent) {
    getPath(item.data.attributes.parent)
  }
  path.push({
    id: item.data.id,
    name: item.data.attributes.name,
  })
  return path
}

export function normalizeCategory(d) {
  const seo =
    d.self.data.attributes.seo &&
    d.self.data.attributes.seo.data &&
    d.self.data.attributes.seo.data.attributes
  let category = {
    id: d.self.data.id,
    name: d.self.data.attributes.name,
    seo: seo || null,
    path: getPath(d.self),
  }
  if (d.children && d.children.data && d.children.data.length) {
    const children = d.children.data
      .map(c => ({
        id: c.id,
        name: c.attributes.name,
        poses: c.attributes.point_of_sales,
      }))
      .filter(c => c.poses > 0)
    category = {
      ...category,
      children,
    }
  }
  if (
    d.self &&
    d.self.data &&
    d.self.data.attributes &&
    d.self.data.attributes.siblings &&
    d.self.data.attributes.siblings.data &&
    d.self.data.attributes.siblings.data.length
  ) {
    const siblings = d.self.data.attributes.siblings.data
      .map(c => ({
        id: c.id,
        name: c.attributes.name,
        poses: c.attributes.point_of_sales,
        path: category.path,
      }))
      .filter(c => c.poses > 0)
    category = {
      ...category,
      siblings,
    }
  }
  return category
}

// assign_filters
export function assignFilter(local_filter, filter) {
  filter.buckets.map((item, key) => {
    const isFiler = local_filter?.buckets.find(
      local_filter_item => local_filter_item.title === item?.title,
    )
    if (!isFiler) {
      local_filter?.buckets.push(item)
    }
  })
}

export const allCountries = ALL_COUNTRIES.map(country => ({
  name: country[0],
  iso2: country[1].toUpperCase(),
  dialCode: country[2],
  priority: country[3] || 0,
  areaCodes: country[4] || null,
}))

export function LOG_ERROR(rollbar, config, name, e, options) {
  // if (config.ROLLBAR_ENABLE_ENV === config.ENVIRONMENT && rollbar) {
  //   rollbar.error(`ERROR ${name}`, {
  //     error: JSON.parse(JSON.stringify(e || '')),
  //     error_response: JSON.parse(JSON.stringify(e?.response?.data || '')),
  //     params: JSON.parse(JSON.stringify(options || '')),
  //   })
  // } else {
  // console.log(`ERROR ${name}`, {
  //   error: JSON.parse(JSON.stringify(e || '')),
  //   error_response: JSON.parse(JSON.stringify(e?.response?.data || '')),
  //   params: JSON.parse(JSON.stringify(options || '')),
  // })
  // }
}

export function slugify(value, options) {
  let s = String(value)
  const opt = Object(options)

  const defaults = {
    delimiter: '-',
    limit: undefined,
    lowercase: true,
    replacements: {},
    transliterate: typeof XRegExp === 'undefined',
  }

  // Merge options
  for (const k in defaults) {
    if (!opt.hasOwnProperty(k)) {
      opt[k] = defaults[k]
    }
  }

  const char_map = {
    // Latin
    À: 'A',
    Á: 'A',
    Â: 'A',
    Ã: 'A',
    Ä: 'A',
    Å: 'A',
    Æ: 'AE',
    Ç: 'C',
    È: 'E',
    É: 'E',
    Ê: 'E',
    Ë: 'E',
    Ì: 'I',
    Í: 'I',
    Î: 'I',
    Ï: 'I',
    Ð: 'D',
    Ñ: 'N',
    Ò: 'O',
    Ó: 'O',
    Ô: 'O',
    Õ: 'O',
    Ö: 'O',
    Ő: 'O',
    Ø: 'O',
    Ù: 'U',
    Ú: 'U',
    Û: 'U',
    Ü: 'U',
    Ű: 'U',
    Ý: 'Y',
    Þ: 'TH',
    ß: 'ss',
    à: 'a',
    á: 'a',
    â: 'a',
    ã: 'a',
    ä: 'a',
    å: 'a',
    æ: 'ae',
    ç: 'c',
    è: 'e',
    é: 'e',
    ê: 'e',
    ë: 'e',
    ì: 'i',
    í: 'i',
    î: 'i',
    ï: 'i',
    ð: 'd',
    ñ: 'n',
    ò: 'o',
    ó: 'o',
    ô: 'o',
    õ: 'o',
    ö: 'o',
    ő: 'o',
    ø: 'o',
    ù: 'u',
    ú: 'u',
    û: 'u',
    ü: 'u',
    ű: 'u',
    ý: 'y',
    þ: 'th',
    ÿ: 'y',

    // Latin symbols
    '©': '(c)',

    // Greek
    Α: 'A',
    Β: 'B',
    Γ: 'G',
    Δ: 'D',
    Ε: 'E',
    Ζ: 'Z',
    Η: 'H',
    Θ: '8',
    Ι: 'I',
    Κ: 'K',
    Λ: 'L',
    Μ: 'M',
    Ν: 'N',
    Ξ: '3',
    Ο: 'O',
    Π: 'P',
    Ρ: 'R',
    Σ: 'S',
    Τ: 'T',
    Υ: 'Y',
    Φ: 'F',
    Χ: 'X',
    Ψ: 'PS',
    Ω: 'W',
    Ά: 'A',
    Έ: 'E',
    Ί: 'I',
    Ό: 'O',
    Ύ: 'Y',
    Ή: 'H',
    Ώ: 'W',
    Ϊ: 'I',
    Ϋ: 'Y',
    α: 'a',
    β: 'b',
    γ: 'g',
    δ: 'd',
    ε: 'e',
    ζ: 'z',
    η: 'h',
    θ: '8',
    ι: 'i',
    κ: 'k',
    λ: 'l',
    μ: 'm',
    ν: 'n',
    ξ: '3',
    ο: 'o',
    π: 'p',
    ρ: 'r',
    σ: 's',
    τ: 't',
    υ: 'y',
    φ: 'f',
    χ: 'x',
    ψ: 'ps',
    ω: 'w',
    ά: 'a',
    έ: 'e',
    ί: 'i',
    ό: 'o',
    ύ: 'y',
    ή: 'h',
    ώ: 'w',
    ς: 's',
    ϊ: 'i',
    ΰ: 'y',
    ϋ: 'y',
    ΐ: 'i',

    // Turkish
    Ş: 'S',
    İ: 'I',
    Ğ: 'G',
    ə: 'e',
    ş: 's',
    ı: 'i',
    ğ: 'g',
    Ə: 'e',

    // Russian
    А: 'A',
    Б: 'B',
    В: 'V',
    Г: 'G',
    Д: 'D',
    Е: 'E',
    Ё: 'Yo',
    Ж: 'Zh',
    З: 'Z',
    И: 'I',
    Й: 'J',
    К: 'K',
    Л: 'L',
    М: 'M',
    Н: 'N',
    О: 'O',
    П: 'P',
    Р: 'R',
    С: 'S',
    Т: 'T',
    У: 'U',
    Ф: 'F',
    Х: 'H',
    Ц: 'C',
    Ч: 'Ch',
    Ш: 'Sh',
    Щ: 'Sh',
    Ъ: '',
    Ы: 'Y',
    Ь: '',
    Э: 'E',
    Ю: 'Yu',
    Я: 'Ya',
    а: 'a',
    б: 'b',
    в: 'v',
    г: 'g',
    д: 'd',
    е: 'e',
    ё: 'yo',
    ж: 'zh',
    з: 'z',
    и: 'i',
    й: 'j',
    к: 'k',
    л: 'l',
    м: 'm',
    н: 'n',
    о: 'o',
    п: 'p',
    р: 'r',
    с: 's',
    т: 't',
    у: 'u',
    ф: 'f',
    х: 'h',
    ц: 'c',
    ч: 'ch',
    ш: 'sh',
    щ: 'sh',
    ъ: '',
    ы: 'y',
    ь: '',
    э: 'e',
    ю: 'yu',
    я: 'ya',

    // Ukrainian
    Є: 'Ye',
    І: 'I',
    Ї: 'Yi',
    Ґ: 'G',
    є: 'ye',
    і: 'i',
    ї: 'yi',
    ґ: 'g',

    // Czech
    Ď: 'D',
    Ě: 'E',
    Ň: 'N',
    Ř: 'R',
    Ť: 'T',
    Ů: 'U',
    ď: 'd',
    ě: 'e',
    ň: 'n',
    ř: 'r',
    ť: 't',
    ů: 'u',

    // Polish
    Ą: 'A',
    Ć: 'C',
    Ę: 'e',
    Ł: 'L',
    Ń: 'N',
    Ś: 'S',
    Ź: 'Z',
    Ż: 'Z',
    ą: 'a',
    ć: 'c',
    ę: 'e',
    ł: 'l',
    ń: 'n',
    ś: 's',
    ź: 'z',
    ż: 'z',

    // Latvian
    Ā: 'A',
    Č: 'C',
    Ē: 'E',
    Ģ: 'G',
    Ī: 'i',
    Ķ: 'k',
    Ļ: 'L',
    Ņ: 'N',
    Š: 'S',
    Ū: 'u',
    Ž: 'Z',
    ā: 'a',
    č: 'c',
    ē: 'e',
    ģ: 'g',
    ī: 'i',
    ķ: 'k',
    ļ: 'l',
    ņ: 'n',
    š: 's',
    ū: 'u',
    ž: 'z',
  }

  // Make custom replacements
  for (const k in opt.replacements) {
    s = s.replace(RegExp(k, 'g'), opt.replacements[k])
  }

  // Transliterate characters to ASCII
  if (opt.transliterate) {
    for (const k in char_map) {
      s = s.replace(RegExp(k, 'g'), char_map[k])
    }
  }

  // Replace non-alphanumeric characters with our delimiter
  const alnum =
    typeof XRegExp === 'undefined'
      ? RegExp('[^a-z0-9]+', 'ig')
      : XRegExp('[^\\p{L}\\p{N}]+', 'ig')
  s = s.replace(alnum, opt.delimiter)

  // Remove duplicate delimiters
  s = s.replace(RegExp('[' + opt.delimiter + ']{2,}', 'g'), opt.delimiter)

  // Truncate slug to max. characters
  s = s.substring(0, opt.limit)

  // Remove delimiter from ends
  s = s.replace(
    RegExp('(^' + opt.delimiter + '|' + opt.delimiter + '$)', 'g'),
    '',
  )

  return opt.lowercase ? s?.toLowerCase() : s
}

export function updateCartIndexInLocalStorage(newProductData, action = 'add') {
  // Get existing cart index data from Local Storage
  const existingDataStr = localStorage.getItem('cartIndex')
  const existingData = existingDataStr ? JSON.parse(existingDataStr) : []

  // Check if the product already exists in the array
  const existingProductIndex = existingData.findIndex(
    item => item.product_id === newProductData.product_id,
  )

  if (action === 'add') {
    if (existingProductIndex > -1) {
      // Update existing product data
      existingData[existingProductIndex] = newProductData
    } else {
      // Add new product data
      existingData.push(newProductData)
    }
  } else if (action === 'remove' && existingProductIndex > -1) {
    // Remove existing product data
    existingData.splice(existingProductIndex, 1)
  }

  // Save updated cart index data back to Local Storage
  localStorage.setItem('cartIndex', JSON.stringify(existingData))
}

// lodash

export function uniqBy(arr, ...args) {
  let iteratee = args.pop()
  if (typeof iteratee === 'string') {
    const prop = iteratee
    iteratee = item => item[prop]
  }

  return arr
    .concat(...args)
    .filter(
      (x, i, self) => i === self.findIndex(y => iteratee(x) === iteratee(y)),
    )
}

export const debounce = (callback, time) => {
  let isCooldown = false
  var timeout
  function debunced(...args) {
    const lastThis = this
    function handleCallback(...args) {
      callback.apply(lastThis, args)
      isCooldown = true
    }
    if (!isCooldown) {
      handleCallback(...args)
      timeout = setTimeout(() => {
        isCooldown = false
      }, time)
    } else {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        isCooldown = false
        handleCallback(...args)
      }, time)
    }
  }
  return debunced
}
export function throttle(func, ms) {
  let isThrottled = false
  let avedArgs
  let savedThis
  let savedArgs

  function wrapper() {
    if (isThrottled) {
      // (2)
      savedArgs = arguments
      savedThis = this
      return
    }

    func.apply(this, arguments) // (1)

    isThrottled = true

    setTimeout(function () {
      isThrottled = false // (3)
      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs)
        savedArgs = savedThis = null
      }
    }, ms)
  }

  return wrapper
}

export function groupBy(inventory, type) {
  return Object.groupBy(inventory, ({ type }) => type)
}

export function orderBy(array, fields, sorted) {
  const sortField = (a, b, iField) => {
    return iField >= fields.length
      ? 0
      : a[fields[iField]] == b[fields[iField]]
      ? sortField(a, b, iField + 1)
      : (a[fields[iField]] > b[fields[iField]] ? 1 : -1) *
        (sorted[iField] == 'desc' ? -1 : 1)
  }
  return [...array].sort((a, b) => {
    return sortField(a, b, 0)
  })
}

export function chunk(arr, chunkSize = 1, cache = []) {
  const tmp = [...arr]
  if (chunkSize <= 0) return cache
  while (tmp.length) cache.push(tmp.splice(0, chunkSize))
  return cache
}

export function difference(arr1, arr2) {
  return arr1.filter(x => !arr2.includes(x))
}

export function uniq(arr) {
  return [...new Set(arr)]
}

export function min(nums) {
  if (nums.length) return Math.min(...nums)
}

export function max(nums) {
  if (nums.length) return Math.max(...nums)
}

export function setUrl(action_url) {
  const index_for_slice = action_url.lastIndexOf('promotion')
  if (index_for_slice >= 0) {
    const slag = action_url.slice(index_for_slice + 10, action_url.length)
    return {
      name: 'promotion-slug',
      params: {
        slug: slag,
      },
    }
  } else {
    const index_for_slice = action_url.lastIndexOf('promo_page')
    const slag = action_url.slice(index_for_slice + 11, action_url.length)
    return {
      name: 'promo_page-slug',
      params: {
        slug: slag,
      },
    }
  }
}

export function highlight(word, query) {
  if (!query) {
    return word
  }
  query = query.replace('\\', '')
  query = query.replace(/[)(]/g, '')
  query = query.replace('[', '\\[')
  query = query.replace(']', '\\]')
  return word.replace(new RegExp(query, 'gi'), match => {
    return '<strong>' + match + '</strong>'
  })
}

export function discountCalculate(old, retail) {
  if (!old && retail) return false
  return (((old - retail) / old) * 100).toFixed()
}

export function getPosition(el) {
  let xPos = 0
  let yPos = 0

  while (el) {
    if (el.tagName === 'BODY') {
      // deal with browser quirks with body/window/document and page scroll
      const xScroll = el.scrollLeft || document.documentElement.scrollLeft
      const yScroll = el.scrollTop || document.documentElement.scrollTop

      xPos += el.offsetLeft - xScroll + el.clientLeft
      yPos += el.offsetTop - yScroll + el.clientTop
    } else {
      // for all other non-BODY elements
      xPos += el.offsetLeft - el.scrollLeft + el.clientLeft
      yPos += el.offsetTop - el.scrollTop + el.clientTop
    }

    el = el.offsetParent
  }
  return {
    x: xPos,
    y: yPos,
  }
}

export function calculationInstallment(amount, months) {
  return (Math.ceil((amount / months) * 100) / 100).toFixed(2)
}

export const savedAddressCookie = function ($auth) {
  const CheckoutStore = useCheckoutStore()
  const shipping = CheckoutStore.orderPackage?.shipping
  const methods = ['COURIER_STANDARD', 'COURIER_EXPRESS']
  const isCalculatedDelivery = CheckoutStore.orderPackage.service_fees.find(
    fee =>
      fee.code === 'DELIVERY_FEE' && fee.calculation_status === 'CALCULATED',
  )

  if (
    shipping?.geocodedAddress?.precision === 'exact' &&
    methods.includes(shipping?.method) &&
    isCalculatedDelivery
  ) {
    const save_address = {
      building: shipping?.address?.building,
      city: shipping?.address?.city,
      latitude: shipping?.latitude,
      longitude: shipping?.longitude,
      precision: shipping?.address?.precision,
      street: shipping?.address?.street,
    }
    $auth.$storage.setUniversal('save_address', JSON.stringify(save_address))
  }
}

export const labelByChances = value =>
  value <= 1
    ? 'market.lattery_chance_one'
    : value <= 4
    ? 'market.lattery_chance_few'
    : 'market.lattery_chances'

export const convertCategoryTreeToArray = (tree, lang) => {
  let array = [
    {
      name: tree['name_' + lang],
      id: tree.id,
    },
  ]
  if (tree.parent_tree) {
    array = [...array, ...convertCategoryTreeToArray(tree.parent_tree, lang)]
  }
  return array
}

export function formatCurrency(value = 0, options = {}) {
  const defaults = {
    symbol: '₼',
    symbolPosition: 'back',
    useSpacing: true,
    thousandsSeparator: ' ',
    separator: '.',
    fractionCount: 2,
    fractionSeparator: '.',
    avoidEmptyDecimals: false,
    emptyDecimalsString: '',
  }
  const config = { ...defaults, ...options }
  let formattedValue = Number(value)
    .toFixed(config.fractionCount)
    .replace('.', config.fractionSeparator)

  if (config.avoidEmptyDecimals) {
    const regex = new RegExp(`(\\${config.fractionSeparator}[0]+)$`)
    formattedValue = formattedValue.replace(
      regex,
      config.emptyDecimalsString ? config.emptyDecimalsString : '',
    )
  }
  if (config.useSpacing) {
    formattedValue = formattedValue.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      config.thousandsSeparator,
    )
  }
  return config.symbolPosition === 'front'
    ? config.symbol + (config.useSpacing ? ' ' : '') + formattedValue
    : formattedValue + (config.useSpacing ? ' ' : '') + config.symbol
}

export function rtbAnalytics(locale, event) {
  const lang = {
    eventType: 'custom',
    name: 'language',
    value: locale,
  }
  const customerStore = useCustomer()
  const UID = {
    eventType: 'uid',
    id: customerStore?.userProfile?.attributes?.phone || undefined,
  }
  ;(rtbhEvents = window.rtbhEvents || []).push(lang, event, UID)
}

export function filterQueryWhiteList(query) {
  const whiteList = {}

  WHITE_LIST_MARKET_PRODUCTS_PARAMS.forEach(key => {
    whiteList[key] = true
  })
  const clearQuery = {}
  Object.keys(query).forEach(key => {
    if (whiteList[key]) {
      clearQuery[key] = query[key]
    }
  })

  return clearQuery
}
export function decodeQuery(query) {
  const decodeQuery = {}
  Object.keys(query).forEach(key => {
    decodeQuery[key] = decodeURIComponent(query[key])
  })

  return decodeQuery
}
decodeURIComponent

export function generateItemListName({ route, list_name, query = null }) {
  const excludedListNames = ['search_bestsellers']
  const queryTemplate = query ? `||${query}` : ''
  const isSearch = route?.query?.from_search
  const isSuggest = route?.query?.event === 'view_search_tips'
  const isViewAllProducts =
    route?.query.event === 'view_search_results' &&
    route?.query?.search_type === 'view_all'
  if (!isSearch) return list_name
  if (!isSuggest) {
    return excludedListNames.includes(list_name)
      ? `${list_name}${queryTemplate}`
      : isViewAllProducts
      ? `search_view_all${queryTemplate}`
      : `search${queryTemplate}`
  } else {
    const locations = [
      'brand',
      'tag',
      'merchant',
      'product',
      'categories',
      'search',
    ]
    const location = locations.find(loc => route.name.includes(loc)) || ''
    const formattedLocation =
      location === 'categories'
        ? 'category'
        : location === 'search'
        ? 'tag'
        : location
    return `search_suggest_${formattedLocation}${queryTemplate}`
  }
}

export function getSearchQueries({ routeQueries }) {
  const query = {}
  Object.entries(routeQueries).forEach(([key, value]) => {
    if (SEARCH_QUERIES.includes(key)) {
      query[key] = value
    }
  })
  return query
}
