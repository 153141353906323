export default {
  SET_DATA_LAYER_SOURCE_SEARCH(type) {
    this.data_layer_source = type
  },
  SET_BEGIN_CHECKOUT_METHODS(method) {
    this.begin_checkout_method = method
  },
  SET_BEGIN_CHECKOUT_SOURSE(source) {
    this.begin_checkout_source = source
  },
  SET_DATA_CLICK_SUGGESTS(type) {
    this.data_layer_click_suggests = type
  },
}
