import { DEBUG_KEY } from '@/constants'
let isAlreadyFetchingAccessToken = false
let subscribers = []

function onAccessTokenFetched(accessToken) {
  subscribers = subscribers.filter(callback => callback(accessToken))
}

function addSubscriber(callback) {
  subscribers.push(callback)
}

function memorySizeOf(obj) {
  let bytes = 0

  function sizeOf(obj) {
    if (obj !== null && obj !== undefined) {
      switch (typeof obj) {
        case 'number':
          bytes += 8
          break
        case 'string':
          bytes += obj.length * 2
          break
        case 'boolean':
          bytes += 4
          break
        case 'object':
          // eslint-disable-next-line no-case-declarations
          const objClass = Object.prototype.toString.call(obj).slice(8, -1)
          if (objClass === 'Object' || objClass === 'Array') {
            for (const key in obj) {
              // eslint-disable-next-line no-prototype-builtins
              if (!obj.hasOwnProperty(key)) continue
              sizeOf(obj[key])
            }
          } else bytes += obj.toString().length * 2
          break
      }
    }
    return bytes
  }

  function formatByteSize(bytes) {
    let size
    if (bytes < 1024) size = bytes + ' bytes'
    else if (bytes < 1048576) size = (bytes / 1024).toFixed(3) + ' KiB'
    else if (bytes < 1073741824) size = (bytes / 1048576).toFixed(3) + ' MiB'
    else size = (bytes / 1073741824).toFixed(3) + ' GiB'
    return { size, bytes }
  }

  return formatByteSize(sizeOf(obj))
}

export default function ({ $axios, redirect, store, app, route, $config }) {
  $axios.defaults.timeout = 15000

  $axios.onError(async error => {
    const code = parseInt(error.response && error.response.status)
    const message = error.message
    if (message.includes('ECONNABORTED')) {
      console.error(
        `Request error: Connection aborted for url: ${error.config.url}`,
      )
      if (error.config.data) {
        console.log(`With data: ${error.config.data}`)
      }
    }
    if (message.includes('timeout')) {
      console.error(`Request error: Timeout for url: ${error.config.url}`)
      if (error.config.data) {
        console.log(`With data: ${error.config.data}`)
      }
    }
    if (code === 500) {
      console.error(`Server error 500 for url : ${error.config.url}`)
    }
    if (code === 404) {
      if (
        error.config.baseURL === $config.CATALOG_API_URL ||
        error.config.baseURL === $config.NODE_CATALOG_API_URL
      ) {
        try {
          // Fetch the redirect information
          const redirectAxios = $axios.create({
            baseURL: $config.SEO_SERVICE_BASE_URL,
          })
          redirectAxios.interceptors.response.use(
            response => {
              if (process.server) {
                redirect(response.request.res.responseUrl)
              } else {
                redirect(response.request.responseURL)
              }
            },
            error => {
              return Promise.resolve()
            },
          )

          await redirectAxios.get(`/api/v1/redirects${route.path}`)
        } catch (redirectError) {
          console.error('Redirect request failed:', redirectError)
        }
      }
    }
    if (code === 401) {
      const {
        config,
        response: { status },
      } = error
      const originalRequest = config

      if (config.url.includes('refresh-token')) {
        app.$auth.$storage.setUniversal('loggedIn', null, true)
        app.$auth.$storage.setUniversal('user', null, true)
        app.$auth.$storage.setUniversal('access_token', null, true)
        app.$auth.$storage.setUniversal('refresh_token', null, true)
        app.$auth.$storage.setUniversal('X-Trace-ID', null, true)
        app.$axios.setToken(false)
        redirect(401, {
          path: app.localePath('auth-authentication'),
        })
      }

      if (status === 401 && !config.url.includes('auth-authentication')) {
        if (!isAlreadyFetchingAccessToken) {
          isAlreadyFetchingAccessToken = true
          store.dispatch('FETCH_ACCESS_TOKEN').then(accessToken => {
            isAlreadyFetchingAccessToken = false
            onAccessTokenFetched(accessToken)
          })
        }

        return new Promise(resolve => {
          addSubscriber(accessToken => {
            originalRequest.headers.Authorization = 'Bearer ' + accessToken
            resolve($axios(originalRequest))
          })
        })
      }
    }
    return Promise.reject(error)
  })

  $axios.onRequest(config => {
    config.headers.common['Content-Language'] = app.i18n.locale
    config.headers.common['Accept-Language'] = app.i18n.locale
    config.headers.common.HTTP_ACCEPT_LANGUAGE = app.i18n.locale
    config.headers.common.HTTP_CONTENT_LANGUAGE = app.i18n.locale

    const accessToken = app.$auth.$storage.getCookie('access_token')

    if (accessToken) {
      $axios.setToken(accessToken, 'Bearer')
    } else {
      $axios.setToken(false)
    }

    if (app.$auth.$storage.getCookie('X-Trace-ID')) {
      config.headers.common['X-Trace-ID'] =
        app.$auth.$storage.getCookie('X-Trace-ID') + '-' + new Date().getTime()
    }
  })

  $axios.onResponse(response => {
    const isDebug = app.$cookies.get('debug' + DEBUG_KEY) == true
    if (isDebug) {
      console.info('URL: ' + response?.config?.baseURL + response?.config?.url)
      console.group('RESPONSE:')
      console.log('RESPONSE_STATUS: ' + response.status)
      console.log('RESPONSE_DATA: ' + JSON.stringify(response.data))
      console.groupEnd()
    }
  })
}
