export default {
  isPremiumPartner(state) {
    return product => {
      const current_product = product || state.product
      return (
        current_product?.offers?.find(
          offer => offer?.id === current_product?.default_offer_id,
        )?.seller_marketing_name?.partner_role_name === 'FBU'
      )
    }
  },
  isProductCB(state) {
    return () => {
      const listCartSellers = state.cart?.sellers.map(seller => seller) || []

      const listCartProductsRoleCB =
        listCartSellers
          ?.filter(seller => seller?.role === 'CB')
          ?.flatMap(seller => seller.line_items) || []

      const isActive = listCartProductsRoleCB.find(
        product => product.state === 'active',
      )
      if (isActive) {
        return listCartProductsRoleCB.find(
          product => product.is_selected_for_checkout === true,
        )
      } else {
        return false
      }
    }
  },
}
