export default () => ({
  credit_user_balance: null,
  error_fin_code: false,
  check_otp_credit: false,
  startModalScoring: false,
  startShowingModals: false,
  // startShowingModals: true, // must be false
  downPaymentApproved: false,
  credit_annuity: null,
  error_loan_applications: false,
  set_loan_application: {
    id: null,
    monthly_payment_amount: null,
    annual_rate: null,
    loan_term_months: null,
    loan_fee: null,
    first_name: '',
    last_name: '',
    middle_name: '',
    phone_number: '',
    phone_friends_1: '',
    phone_friends_2: '',
    fin_code: '',
    email: '',
    credit_bureau_consent: true,
    monthly_commission_rate: null,
    down_payment_amount: null,
    shipping_cost: null,
    imei_code: true,
    loan_first_pay: null,
    requested_amount: null,
    downpayment_planned_amount: null,
    valid: false,
  },
  orders_list_loan_application: [],
  not_available_cart: false,
  loan_application_orders_pagination: null,
  scoring_state_events_credit_scr: null,
  offersLoanApplication: {},
  scoringOffersLoanApplicationList: [],
  showSecondPopup: false,
  isCreditLineWithoutCart: false,
  sourceScrCreditApplication: 'other',
  phoneNumberBlockedBroker: false,
  listLoanOfferAmount: null,
  currentMonthFirstCredit: {},
  setPhoneNumber: '',
})
