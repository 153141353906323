import { LOG_ERROR, serialize } from '@/utilities'
import { useCheckoutStore } from '@/stores/checkout'
// import * as scoring_mock from '@/mock_data/scoring.json'
// import * as product_mock from '@/mock_data/product.json'
// import mock_data from '@/mock_data/getScoringOffers'

export default {
  async updateLoanApplication({ applicationId, applicationData }) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$config.NODE_CART_API_URL.replace('v1', 'v5') ||
        this.$nuxt.$config.CART_API_URL.replace('v1', 'v5')
    } else {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.CART_API_URL.replace('v1', 'v5')
    }
    const {
      first_name,
      middle_name,
      last_name,
      phone_number,
      fin_code,
      is_bank_customer,
      card_pan,
      // email,
      loan_first_pay,
      loan_fee,
      loan_term_months,
      monthly_payment_amount,
      // issue_fee_percent,
      // downpayment_planned_amount,
      monthly_commission_rate,
      // annual_rate,
      credit_bureau_consent,
      phone_friends_1,
      phone_friends_2,
      imei_code,
      bb_user_id,
      bb_device_id,
      premium_promocode,
    } = applicationData
    const loanForUpdate = {
      first_name,
      middle_name,
      last_name,
      phone_number,
      fin_code,
      is_bank_customer,
      card_pan,
      loan_term_months,
      monthly_payment_amount,
      // downpayment_planned_amount,
      // email,
      // annual_rate,
      // issue_fee_percent: monthly_commission_rate,
      // initial_payment_amount: loan_first_pay,
      credit_bureau_consent: true,
      additional_phone_number1: phone_friends_1.replaceAll('-', ''),
      additional_phone_number2: phone_friends_2.replaceAll('-', ''),
      imei_block_consent: true,
      bb_user_id,
      bb_device_id,
      premium_promocode,
    }
    if (applicationData?.downpayment_planned_amount) {
      loanForUpdate.downpayment_planned_amount =
        applicationData.downpayment_planned_amount
    }
    if (applicationData?.down_payment_amount) {
      loanForUpdate.downpayment_planned_amount =
        applicationData.down_payment_amount
    }
    if (applicationData?.loan_first_pay) {
      loanForUpdate.initial_payment_amount = applicationData.loan_first_pay
    }
    if (this.checkFirstCredit) {
      loanForUpdate.loan_term_months = null
    }
    if (!this.checkFirstCredit) {
      loanForUpdate.issue_fee_percent = monthly_commission_rate
    }
    const { data } = await this.$nuxt.$axios.patch(
      `/loan-applications/${applicationId}`,
      loanForUpdate,
    )
    return data
  },
  async getLoanApplication({ id }) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      const response = await this.$nuxt.$axios.get(`/loan-applications/${id}`)

      // this.set_loan_application = mock_data?.set_loan_application

      this.set_loan_application = {
        ...this.set_loan_application,
        ...response.data?.data,
        id: response.data?.data?.id,
      }

      // this.set_loan_application.id = response.data?.data?.id
      return response?.data?.data

      // return  this.set_loan_application
    } catch (e) {
      console.log('Error getLoanApplication', e)
      return e.response?.data
    }
  },
  async setLoanApplicationState(application_id, options) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL.replace('v1', 'v5') ||
          this.$nuxt.$config.CART_API_URL.replace('v1', 'v5')
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.CART_API_URL.replace('v1', 'v5')
      }
      const { data } = await this.$nuxt.$axios.patch(
        `/loan-applications/${application_id}`,
        options,
      )
      return data
    } catch (e) {
      console.log('Error SET_LOAN_APPLICATION_STATE', e)
      return e.response
    }
  },
  async GET_ALL_LOAN_APPLICATION(pagination) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CART_API_URL || this.$nuxt.$config.CART_API_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
    }
    const { data = {} } = await this.$nuxt.$axios
      .get(`/loan-applications?${serialize(pagination)}`)
      .catch(e => {
        console.log('Error GET_ALL_LOAN_APPLICATION', e)
        return { data: 'error' }
      })

    this.loan_application_orders_pagination = data?.meta
    if (pagination?.page === 1) {
      this.orders_list_loan_application = data?.data
    } else if (pagination?.page && pagination?.page > 1) {
      const list_orders = this.orders_list_loan_application
      list_orders.push(...data?.data)
      this.orders_list_loan_application = list_orders
    }
  },
  async CHECK_USER_CREDIT_BALANCE() {
    const isLogin = Boolean(this.$nuxt.$auth.$storage.getCookie('access_token'))
    try {
      if (isLogin) {
        if (process.server) {
          this.$nuxt.$axios.defaults.baseURL =
            this.$nuxt.$config.NODE_BROKER_BASE_URL ||
            this.$nuxt.$config.BROKER_BASE_URL
        } else {
          this.$nuxt.$axios.defaults.baseURL =
            this.$nuxt.$config.BROKER_BASE_URL
        }
        const response = await this.$nuxt.$axios.get(`/api/v2/check_balance`)
        const end_date_credit = response?.data?.сl_actl_end_date
        const is_credit = new Date(end_date_credit).getTime() - Date.now()
        this.credit_user_balance = is_credit >= 0 ? response?.data : {}
      } else {
        this.credit_user_balance = null
      }
      return ''
    } catch (e) {
      console.log('Error useCredit Checkout Error CHECK_USER_CREDIT_BALANCE', e)
    }
  },
  async CHECK_FIN_CODE(code) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_BROKER_BASE_URL ||
          this.$nuxt.$config.BROKER_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.BROKER_BASE_URL
      }
      const response = await this.$nuxt.$axios.get(`/api/v2/check/${code}`)
      if (
        response?.status !== 200 ||
        response?.data?.description === 'Pin code is incorrect'
      ) {
        this.error_fin_code = true
        return false
      } else {
        return true
      }
    } catch (e) {
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'CHECK_FIN_CODE',
        e,
        {
          code,
        },
      )
      console.log('Error CHECK_FIN_CODE', e)
      if (
        e?.response?.status !== 200 ||
        e?.response?.data?.description === 'Pin code is incorrect'
      ) {
        this.error_fin_code = true
        return false
      } else {
        return true
      }
    }
  },
  async SEND_OTP_CODE_CREDIT_VERIFICATION(data) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      const response = await this.$nuxt.$axios.post('/verifications', data)
      return response
    } catch (e) {
      console.log('Error SEND_OTP_CODE_CREDIT_VERIFICATION', e.data)
    }
  },
  async SEND_OTP_CODE_CREDIT_VERIFICATION_BROKER(data) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_BROKER_BASE_URL ||
        this.$nuxt.$config.BROKER_BASE_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.BROKER_BASE_URL
    }
    const response = await this.$nuxt.$axios.post('/api/v2/otp/send', data)
    this.setPhoneNumber = response?.data?.masked_mobile_number
    return response
  },
  async SEND_OTP_CODE_CREDIT_CONFIRM(data) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CART_API_URL || this.$nuxt.$config.CART_API_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
    }
    const response = await this.$nuxt.$axios.post(
      '/verifications/confirmation',
      data,
    )
    return response
  },
  async SEND_OTP_CODE_CREDIT_BROKER(data) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_BROKER_BASE_URL ||
        this.$nuxt.$config.BROKER_BASE_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.BROKER_BASE_URL
    }
    const response = await this.$nuxt.$axios.post('/api/v2/otp/verify', data)
    return response
  },

  async GET_LOAN_APPLICATION_CREDIT_LINE() {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL = (
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
        ).replace('/v1', '/v4')
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.CART_API_URL.replace('/v1', '/v4')
      }
      const {
        data: { data = {} },
      } = await this.$nuxt.$axios.post(`/loan-applications`, {
        process_version: 4,
        origin_latitude: 0,
        origin_longitude: 0,
      })
      this.set_loan_application = {
        ...this.set_loan_application,
        ...data,
      }
    } catch (error) {
      console.log('Error_GET_LOAN_APPLICATION_CREDIT_LINE', error)
      return error.response?.data
    }
  },
  async SET_LOAN_APPLICATION() {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL = (
        this.$config.NODE_CART_API_URL || this.$nuxt.$config.CART_API_URL
      ).replace('/v1', '/v4')
    } else {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.CART_API_URL.replace('/v1', '/v4')
    }

    const {
      first_name,
      middle_name,
      last_name,
      phone_number,
      fin_code,
      is_bank_customer,
      card_pan,
      // email,
      loan_first_pay,
      loan_fee,
      monthly_payment_amount,
      annual_rate,
      credit_bureau_consent,
      phone_friends_1,
      phone_friends_2,
      imei_code,
      bb_user_id,
      bb_device_id,
      premium_promocode,
    } = this.set_loan_application
    const loanForUpdate = {
      first_name,
      middle_name,
      last_name,
      phone_number,
      fin_code,
      is_bank_customer,
      card_pan,
      // email,
      // monthly_payment_amount: null,
      // annual_rate: null,
      // issue_fee_percent: null,
      // initial_payment_amount: loan_first_pay || 0,
      credit_bureau_consent,
      additional_phone_number1: phone_friends_1.replaceAll('-', ''),
      additional_phone_number2: phone_friends_2.replaceAll('-', ''),
      imei_block_consent: imei_code,
      bb_user_id,
      bb_device_id,
      premium_promocode,
    }

    const response = await this.$nuxt.$axios
      .patch(
        `/loan-applications/${this.set_loan_application.id}`,
        loanForUpdate,
      )
      .catch(e => {
        console.log('Error SET_LOAN_APPLICATION', e)
      })
    return response?.data || {}
  },
  async GET_SIMILAR_PRODUCTS({ id, options }) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CATALOG_API_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CATALOG_API_URL
    }
    try {
      const { data } = await this.$nuxt.$axios.get(
        `/v3/market/products/${id}?${serialize(options)}`,
      )

      const { similar_products = [] } = data?.data

      return similar_products
    } catch (e) {
      LOG_ERROR(
        this?.$nuxt.$rollbar,
        this?.$nuxt.$config,
        'GET_SIMILAR_PRODUCTS',
        e,
        {
          options,
        },
      )
    }
  },
  async getScoringOffers(application_loan_id) {
    this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.BROKER_BASE_URL
    try {
      const { data } = await this.$nuxt.$axios.get(
        `/api/v2/application/scoring_offers?application_loan_id=${application_loan_id}`,
      )
      return data || []
    } catch (error) {
      if (error?.response?.status === 409) {
        return 'bank_need_more_time'
      } else {
        console.log('Error getScoringOffers', error)
        return 'other_error'
      }
    }
  },

  // getScoringOffersTotalAmount
  async GET_CREDIT_ANNUITY(amount) {
    const checkoutStore = useCheckoutStore()
    if (checkoutStore.orderPackage.payment_method === 'LOAN_ON_DELIVERY') {
      if (this.checkFirstCredit) {
        await this.getFirstCreditAnnuity(amount)
      } else {
        await this.getSecondsCreditAnnuity()
      }
    }
  },

  async getFirstCreditAnnuity(amount) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_BROKER_BASE_URL ||
          this.$nuxt.$config.BROKER_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.BROKER_BASE_URL
      }
      if (amount) {
        const { data } = await this.$nuxt.$axios.get(
          `/api/v2/products?product_code=KB_LOAN_V2&amount=${amount}`,
        )
        // MOCK
        // this.credit_annuity = product_mock?.default
        // MOCK-END
        // RETURN
        this.credit_annuity = data || {}
        // RETURN-END
        if (this.set_loan_application?.loan_term_months) {
          const current_annuity = this.credit_annuity?.annuity.find(annuit => {
            return annuit.term === this.set_loan_application.loan_term_months
          })
          this.set_loan_application.monthly_payment_amount =
            current_annuity?.amount_per_month
          this.set_loan_application.annual_rate = current_annuity?.fee_rate
        } else {
          const first_annuity = JSON.parse(
            JSON.stringify(this.credit_annuity?.annuity),
          ).sort((a, b) => b.term - a.term)?.[0]
          this.set_loan_application.loan_term_months = first_annuity.term
          this.set_loan_application.monthly_payment_amount =
            first_annuity.amount_per_month
          this.set_loan_application.annual_rate = first_annuity.fee_rate
          this.set_loan_application.base_rate = first_annuity.annual_rate

          this.set_loan_application.loan_fee =
            this.credit_annuity?.loan_fee || 0
          this.set_loan_application.loan_first_pay =
            this.credit_annuity.loan_first_pay || 0
        }
      } else {
        return {}
      }
    } catch (e) {
      console.log('Error GET_CREDIT_ANNUITY', e)
    }
  },
  async getSecondsCreditAnnuity() {
    const checkoutStore = useCheckoutStore()

    const options = {
      customer_ext_id: this.$nuxt.$auth.$storage.getUniversal('user_ext_id'),
      amount: (
        checkoutStore.orderPackage.shipping_cost +
        checkoutStore.orderPackage.total_amount
      ).toFixed(2),
    }
    // this change GET_CREDIT_ANNUITY
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_BROKER_BASE_URL ||
        this.$nuxt.$config.BROKER_BASE_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.BROKER_BASE_URL
    }

    try {
      const response = await this.$nuxt.$axios.get(
        `/api/v2/customer/scoring_offers?${serialize(options)}`,
      )
      let first_annuity = {}
      if (this.set_loan_application.loan_term_months) {
        first_annuity = response?.data
          .sort((a, b) => b.loan_term - a.loan_term)
          .find(
            annuity =>
              annuity.loan_term === this.set_loan_application.loan_term_months,
          )
      } else {
        first_annuity = response?.data.sort(
          (a, b) => b.loan_term - a.loan_term,
        )?.[0]
      }

      this.set_loan_application.loan_term_months = first_annuity.loan_term

      this.set_loan_application.monthly_payment_amount =
        first_annuity.monthly_payment_amount
      this.set_loan_application.monthly_commission_rate =
        first_annuity.monthly_commission_rate
      this.set_loan_application.down_payment_amount =
        first_annuity.down_payment_amount
      this.listLoanOfferAmount = response?.data?.sort(
        (a, b) => b?.loan_term - a?.loan_term,
      )
      return response?.data || []
    } catch (error) {
      return 'other_error'
    }
  },
  clearSetLoanApplication() {
    this.set_loan_application = {
      id: null,
      monthly_payment_amount: null,
      annual_rate: null,
      loan_term_months: null,
      loan_fee: null,
      first_name: '',
      last_name: '',
      middle_name: '',
      phone_number: '',
      phone_friends_1: '',
      phone_friends_2: '',
      fin_code: '',
      email: '',
      credit_bureau_consent: true,
      monthly_commission_rate: null,
      down_payment_amount: null,
      shipping_cost: null,
      imei_code: true,
      loan_first_pay: null,
      requested_amount: null,
      downpayment_planned_amount: null,
      valid: false,
    }
  },
}
