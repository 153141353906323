import { useUiStore } from '@/stores/ui'

export default function ({ store, app }) {
  // store.dispatch('ui/INIT_RESOLUTION')
  const uiStore = useUiStore()
  uiStore.initResolution()
  if (store?.state?.auth?.loggedIn && typeof exponea !== 'undefined') {
    exponea.identify({
      customer_ext_id: store?.state?.auth?.user?.ext_id,
    })
  }
}
