import { mapGetters } from 'vuex'
import { scrollTo } from '@/utilities'
import seoDataLayer from '@/mixins/setDataLayer'
import { useCheckoutStore } from '@/stores/checkout'

export default {
  data() {
    return {
      isOpenAvailablePopup: false,
    }
  },
  mixins: [seoDataLayer],
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
    }),
    listCartProductsLineItems() {
      return (
        this.marketStore?.cart?.sellers?.flatMap(seller => seller.line_items) ||
        []
      )
    },

    leastOneCheckNeedProgress() {
      let check_need = false
      this.listCartProductsLineItems.forEach(item => {
        if (
          item.availability_check_needed &&
          item.availability_check_state === 'IN_PROGRESS'
        ) {
          check_need = true
        }
      })
      return check_need
    },
    leastOneMoreAvailable() {
      let moreAvailable = false
      this.marketStore.cart.sellers?.forEach(partner => {
        partner.line_items.forEach(item => {
          if (
            item.allowed_quantity !== 0 &&
            item.quantity > item.allowed_quantity &&
            item.min_quantity < item.allowed_quantity
          ) {
            moreAvailable = true
          }
        })
      })
      return moreAvailable
    },
    leastOneAvailable() {
      let available = false
      this.listCartProductsLineItems.forEach(item => {
        if (
          item.availability_check_state === 'AVAILABLE' &&
          item.state === 'active'
        ) {
          available = true
        }
      })
      return available
    },
    everethingNotAvailable() {
      return this.marketStore.cart?.sellers.every(partner =>
        partner.line_items.every(product => product.state !== 'active'),
      )
    },
    checkHasGrosseryProducts() {
      const partners_grossery = this.marketStore.cart?.sellers
        .find(
          partner => partner?.id == this.grosseryStore.grossery_merchant_uid,
        )
        ?.line_items.filter(product => {
          return product.is_selected_for_checkout && product.state === 'active'
        })
      const ather_partners = this.marketStore.cart?.sellers.filter(
        partner => partner?.id !== this.grosseryStore.grossery_merchant_uid,
      )
      const ather_partners_products = []
      ather_partners.forEach(partner => {
        const is_product = partner.line_items.filter(product => {
          return product.is_selected_for_checkout && product.state === 'active'
        })
        if (is_product?.length) {
          ather_partners_products.push(...is_product)
        }
      })
      return (
        !['GROCERY', 'NOT_GROCERY'].includes(
          this.grosseryStore.typeGrosseryCartMode,
        ) &&
        Boolean(partners_grossery?.length && ather_partners_products?.length)
      )
    },
    setGrosseryOrNotGrossery() {
      const partners_grossery = this.marketStore.cart?.sellers
        .find(
          partner => partner?.id == this.grosseryStore.grossery_merchant_uid,
        )
        ?.line_items.filter(product => {
          return product.is_selected_for_checkout && product.state === 'active'
        })
      const ather_partners = this.marketStore.cart?.sellers.filter(
        partner => partner?.id !== this.grosseryStore.grossery_merchant_uid,
      )
      const ather_partners_products = []
      ather_partners.forEach(partner => {
        const is_product = partner.line_items.filter(product => {
          return product.is_selected_for_checkout && product.state === 'active'
        })
        if (is_product?.length) {
          ather_partners_products.push(...is_product)
        }
      })
      return ['GROCERY', 'NOT_GROCERY'].includes(
        this.grosseryStore.typeGrosseryCartMode,
      )
        ? this.grosseryStore.typeGrosseryCartMode
        : partners_grossery?.length
        ? 'GROCERY'
        : ather_partners_products?.length
        ? 'NOT_GROCERY'
        : 'ALL_PRODUCTS'
    },
  },
  watch: {
    continueCheckoutWithoutGrossery(val) {
      if (val) {
        this.goToCheckout()
        this.continueCheckoutWithoutGrossery = false
      }
    },
  },

  methods: {
    goToCheckout() {
      if (!this.isAuthenticated && this.marketStore.isProductCB()) {
        this.$router.push({
          path: this.localePath('auth-authentication'),
        })
        return
      }
      if (this.checkHasGrosseryProducts) {
        this.grosseryStore.showPopupChooseGrosseryCheckout = true
        return
      } else {
        this.grosseryStore.updateTypeGrosseryCartMode(
          this.setGrosseryOrNotGrossery,
        )
      }
      if (!this.leastOneAvailable && !this.everethingNotAvailable) {
        this.uiStore.availabilityGoodsSpecified(true)
        return
      }
      if (
        this.marketStore.cart?.total_amount < this.marketStore.minOrderAmount
      ) {
        this.uiStore.isPopupCartVisible = false
        this.uiStore.isPopupCartMinAmount = true
        return
      }

      if (this.marketStore.customerForOrder) {
        this.marketStore.customerForOrder = null
      }
      if (this.leastOneMoreAvailable) {
        this.isOpenAvailablePopup = true
      } else if (this.leastOneCheckNeedProgress) {
        this.uiStore.isCheckoutWarningPopup = {
          visible: true,
          buttonFunc: this.goToCheckoutFunc,
        }
      } else if (this.everethingNotAvailable) {
        this.uiStore.isBasketNotAvailablePopup = true
      } else {
        this.goToCheckoutFunc()
      }
      const checkoutStore = useCheckoutStore()
      checkoutStore.warning_merge_anonymous_cart = false
    },
    goToCheckoutFunc() {
      this.$router.push(
        this.localePath({
          name: 'checkout',
        }),
      )
    },
    goToHasError() {
      this.isOpenAvailablePopup = false
      const firstErrorQty = document.querySelector('.ProductQuantity.has-error')
      if (firstErrorQty)
        scrollTo(
          document.querySelector('.Layout'),
          firstErrorQty.offsetTop + 150,
          250,
        )
    },
  },
}
