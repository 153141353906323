import { useCheckoutStore } from '~/stores/checkout'

export default {
  setPaymentMethod() {
    // 'CASH_ON_DELIVERY' - cash -"Наличными"
    // 'CARD_ON_DELIVERY - card_courier  "Банковской картой при получении"
    // 'CARD_ECOM_KB' - card_online "Банковской картой онлайн"
    // 'LOAN_ON_DELIVERY' -loan - рассрочка онлайн
    // 'UMICO_BONUS' -bonus - юмико бонус
    return method => {
      let payment_method
      switch (method) {
        case 'CASH_ON_DELIVERY':
          payment_method = 'cash'
          break
        case 'CARD_ON_DELIVERY':
          payment_method = 'card_courier'
          break
        case 'CARD_ECOM_KB':
          payment_method = 'card_online'
          break
        case 'LOAN_ON_DELIVERY':
          payment_method = 'loan'
          break
        case 'BB_CARD_ECOM_KB':
          payment_method = 'card_online'
          break
        case 'CARD_ECOM_KB_INSTALLMENT':
          payment_method = 'card_online'
          break
        case 'UMICO_BONUS':
          payment_method = 'bonus'
          break
        default:
          payment_method = 'other'
      }
      return payment_method
    }
  },
  setDeliveryMethod() {
    return () => {
      const CheckoutStore = useCheckoutStore()
      const method = CheckoutStore.orderPackage?.shipping?.method
      let delivery_method
      switch (method) {
        case 'PUDO':
          delivery_method = 'self_pickup'
          break
        case 'COURIER_STANDARD':
          delivery_method = 'courier_delivery'
          break
        case 'COURIER_EXPRESS':
          delivery_method = 'express_delivery'
          break
        case 'PUDO_AZERPOCT':
          delivery_method = 'azerpoct'
          break
        default:
          delivery_method = 'other'
      }
      return delivery_method
    }
  },
}
