import Vue from 'vue'
import { decode, parsePath, withoutBase, withoutTrailingSlash, normalizeURL } from 'ufo'

import { getMatchedComponentsInstances, getChildrenComponentInstancesUsingFetch, promisify, globalHandleError, urlJoin, sanitizeComponent } from './utils'
import NuxtError from '../layouts/error.vue'
import NuxtLoading from './components/nuxt-loading.vue'

import '../assets/css/variables.scss'

import '../assets/css/tailwind.scss'

import '../assets/css/main.scss'

import '../assets/fonts/icons/icons.css'

import '../node_modules/.pnpm/element-ui@2.15.14_vue@2.7.16/node_modules/element-ui/lib/theme-chalk/base.css'

import '../node_modules/.pnpm/element-ui@2.15.14_vue@2.7.16/node_modules/element-ui/lib/theme-chalk/message.css'

import '../node_modules/.pnpm/element-ui@2.15.14_vue@2.7.16/node_modules/element-ui/lib/theme-chalk/popover.css'

import '../node_modules/.pnpm/element-ui@2.15.14_vue@2.7.16/node_modules/element-ui/lib/theme-chalk/table.css'

import '../node_modules/.pnpm/element-ui@2.15.14_vue@2.7.16/node_modules/element-ui/lib/theme-chalk/table-column.css'

import '../node_modules/.pnpm/element-ui@2.15.14_vue@2.7.16/node_modules/element-ui/lib/theme-chalk/slider.css'

import '../node_modules/.pnpm/element-ui@2.15.14_vue@2.7.16/node_modules/element-ui/lib/theme-chalk/image.css'

import '../node_modules/.pnpm/element-ui@2.15.14_vue@2.7.16/node_modules/element-ui/lib/theme-chalk/skeleton.css'

import '../node_modules/.pnpm/element-ui@2.15.14_vue@2.7.16/node_modules/element-ui/lib/theme-chalk/switch.css'

import _6ff620b3 from '../layouts/bingo-terms.vue'
import _0901b4b1 from '../layouts/checkout-empty-without-footer.vue'
import _3d448108 from '../layouts/checkout-empty.vue'
import _33801836 from '../layouts/checkout-line.vue'
import _0d1df8f1 from '../layouts/checkout-without-footer.vue'
import _996cf988 from '../layouts/checkout.vue'
import _49e26aa5 from '../layouts/companies.vue'
import _6f6c098b from '../layouts/default.vue'
import _7efe0585 from '../layouts/error-layout.vue'
import _0b71e28c from '../layouts/header-non-logged.vue'
import _67952e3f from '../layouts/landing-pages-layout.vue'
import _4b2671bb from '../layouts/market-without-footer.vue'
import _7e1bcfb2 from '../layouts/market.vue'
import _d0396f1c from '../layouts/pages-layout-static.vue'
import _0e7d3c49 from '../layouts/pages-layout.vue'
import _45dba2cc from '../layouts/with-footer.vue'
import _1a6ac44b from '../layouts/with-sidebar.vue'

const layouts = { "_bingo-terms": sanitizeComponent(_6ff620b3),"_checkout-empty-without-footer": sanitizeComponent(_0901b4b1),"_checkout-empty": sanitizeComponent(_3d448108),"_checkout-line": sanitizeComponent(_33801836),"_checkout-without-footer": sanitizeComponent(_0d1df8f1),"_checkout": sanitizeComponent(_996cf988),"_companies": sanitizeComponent(_49e26aa5),"_default": sanitizeComponent(_6f6c098b),"_error-layout": sanitizeComponent(_7efe0585),"_header-non-logged": sanitizeComponent(_0b71e28c),"_landing-pages-layout": sanitizeComponent(_67952e3f),"_market-without-footer": sanitizeComponent(_4b2671bb),"_market": sanitizeComponent(_7e1bcfb2),"_pages-layout-static": sanitizeComponent(_d0396f1c),"_pages-layout": sanitizeComponent(_0e7d3c49),"_with-footer": sanitizeComponent(_45dba2cc),"_with-sidebar": sanitizeComponent(_1a6ac44b) }

export default {
  render (h, props) {
    const loadingEl = h('NuxtLoading', { ref: 'loading' })

    const layoutEl = h(this.layout || 'nuxt')
    const templateEl = h('div', {
      domProps: {
        id: '__layout'
      },
      key: this.layoutName
    }, [layoutEl])

    const transitionEl = h('transition', {
      props: {
        name: 'layout',
        mode: 'out-in'
      },
      on: {
        beforeEnter (el) {
          // Ensure to trigger scroll event after calling scrollBehavior
          window.$umico.$nextTick(() => {
            window.$umico.$emit('triggerScroll')
          })
        }
      }
    }, [templateEl])

    return h('div', {
      domProps: {
        id: '__umico'
      }
    }, [
      loadingEl,

      transitionEl
    ])
  },

  data: () => ({
    isOnline: true,

    layout: null,
    layoutName: '',

    nbFetching: 0
    }),

  beforeCreate () {
    Vue.util.defineReactive(this, 'nuxt', this.$options.nuxt)
  },
  created () {
    // Add this.$nuxt in child instances
    this.$root.$options.$umico = this

    if (process.client) {
      // add to window so we can listen when ready
      window.$umico = window.$nuxt = this

      this.refreshOnlineStatus()
      // Setup the listeners
      window.addEventListener('online', this.refreshOnlineStatus)
      window.addEventListener('offline', this.refreshOnlineStatus)
    }
    // Add $nuxt.error()
    this.error = this.nuxt.error
    // Add $nuxt.context
    this.context = this.$options.context
  },

  async mounted () {
    this.$loading = this.$refs.loading
  },

  watch: {
    'nuxt.err': 'errorChanged'
  },

  computed: {
    isOffline () {
      return !this.isOnline
    },

    isFetching () {
      return this.nbFetching > 0
    },
  },

  methods: {
    refreshOnlineStatus () {
      if (process.client) {
        if (typeof window.navigator.onLine === 'undefined') {
          // If the browser doesn't support connection status reports
          // assume that we are online because most apps' only react
          // when they now that the connection has been interrupted
          this.isOnline = true
        } else {
          this.isOnline = window.navigator.onLine
        }
      }
    },

    async refresh () {
      const pages = getMatchedComponentsInstances(this.$route)

      if (!pages.length) {
        return
      }
      this.$loading.start()

      const promises = pages.map(async (page) => {
        let p = []

        // Old fetch
        if (page.$options.fetch && page.$options.fetch.length) {
          p.push(promisify(page.$options.fetch, this.context))
        }

        if (page.$options.asyncData) {
          p.push(
            promisify(page.$options.asyncData, this.context)
              .then((newData) => {
                for (const key in newData) {
                  Vue.set(page.$data, key, newData[key])
                }
              })
          )
        }

        // Wait for asyncData & old fetch to finish
        await Promise.all(p)
        // Cleanup refs
        p = []

        if (page.$fetch) {
          p.push(page.$fetch())
        }
        // Get all component instance to call $fetch
        for (const component of getChildrenComponentInstancesUsingFetch(page.$vnode.componentInstance)) {
          p.push(component.$fetch())
        }

        return Promise.all(p)
      })
      try {
        await Promise.all(promises)
      } catch (error) {
        this.$loading.fail(error)
        globalHandleError(error)
        this.error(error)
      }
      this.$loading.finish()
    },
    errorChanged () {
      if (this.nuxt.err) {
        if (this.$loading) {
          if (this.$loading.fail) {
            this.$loading.fail(this.nuxt.err)
          }
          if (this.$loading.finish) {
            this.$loading.finish()
          }
        }

        let errorLayout = (NuxtError.options || NuxtError).layout;

        if (typeof errorLayout === 'function') {
          errorLayout = errorLayout(this.context)
        }

        this.nuxt.errPageReady = true
        this.setLayout(errorLayout)
      }
    },

    setLayout (layout) {
      if (!layout || !layouts['_' + layout]) {
        layout = 'default'
      }
      this.layoutName = layout
      this.layout = layouts['_' + layout]
      return this.layout
    },
    loadLayout (layout) {
      if (!layout || !layouts['_' + layout]) {
        layout = 'default'
      }
      return Promise.resolve(layouts['_' + layout])
    },
  },

  components: {
    NuxtLoading
  }
}
