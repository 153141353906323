import { defineStore } from 'pinia'

export const useGrosseryStore = defineStore('grossery', {
  state: () => ({
    grossery_merchant_uid: null,
    grossery_total_amount: null,
    showPopupChooseGrosseryCheckout: false,
    showPopupChooseGrosseryCheckoutCredit: false,
    showPopupCheckoutCreditNotAvailable: false,
    showPopupChooseGrosseryNotAvailableMaxCreditLimit: false,
    continueCreditCheckoutWithoutGrossery: false,
    continueCheckoutWithoutGrossery: false,
    grossery_free_delivery_threshold: null,
    typeGrosseryCartMode: 'ALL_PRODUCTS',
  }),
  getters: {
    isGrossery(state) {
      return offer_id => offer_id === state.grossery_merchant_uid
    },
    freeAmountGrocery(state) {
      return amount =>
        amount >= state.grossery_free_delivery_threshold?.value?.number
    },
    isTypeGrossery(state) {
      return state.typeGrosseryCartMode == 'GROCERY'
    },
  },
  actions: {
    async GET_GROCERY_MERCHANT_GUID() {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      const {
        data: {
          data: { value = {} },
        },
      } = await this.$nuxt.$axios.get(`/default-settings/GROCERY_MERCHANT_GUID`)

      this.grossery_merchant_uid = value?.text
    },
    async CART_SELECT_FOR_CHECKOUT(options) {
      const line_item_ids = {
        line_item_ids: options,
      }
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL.replace('/v1', '/v2') ||
          this.$nuxt.$config.CART_API_URL.replace('/v1', '/v2')
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.CART_API_URL.replace('/v1', '/v2')
      }
      const cart_id = this.$nuxt.$auth.$storage.getUniversal('cart_id')
      const urlPackages = this.$nuxt.store.state.auth.loggedIn
        ? `/cart/select-for-checkout`
        : `/anonymous-carts/${cart_id}/select-for-checkout`
      await this.$nuxt.$axios.patch(urlPackages, line_item_ids).catch(e => {
        console.log('Error - CART_SELECT_FOR_CHECKOUT', e)
      })
    },
    async GROCERY_FREE_DELIVERY_THRESHOLD() {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      try {
        const {
          data: { data = {} },
        } = await this.$nuxt.$axios.get(
          `/default-settings/GROCERY_FREE_DELIVERY_THRESHOLD`,
        )
        this.grossery_free_delivery_threshold = data
      } catch {
        this.grossery_free_delivery_threshold = null
      }
    },
    updateTypeGrosseryCartMode(value) {
      this.typeGrosseryCartMode = value
      this.$nuxt.$auth.$storage.setCookie('typeGrosseryCartMode', value)
    },
    getTypeGrosseryCartMode() {
      this.typeGrosseryCartMode = this.$nuxt.$auth.$storage.getCookie(
        'typeGrosseryCartMode',
      )
    },
  },
})
